import axios from 'axios';

export default {
  data() {
    return {
      distribution: {
        distributionList: [],
        characterList: [],
        worldId: null,
        loading: false,
        error: null
      }
    };
  },
  inject: ['snack'],
  methods: {

    /*

     * 配布リストを取得
     * @param {Number} shouhinId - 商品ID
     */
    async getDistributionList(shouhinId) {
      try {
        const response = await axios.get(this.serverPath + `/api/Distribution/getDistributionList/${shouhinId}`)
        return response.data
      } catch (error) {
        throw error
      }
    },

    async createShareLink(shouhinId, officialOptionId, suuryoSentakuId) {

      //distributionInfo @post
      try {
        const body = {
          shouhinId: shouhinId,
          officialOptionId: officialOptionId,
          suuryoSentakuId: suuryoSentakuId
        }

        const response = await axios.post(this.serverPath + `/api/Distribution/distributionInfo`, body)

        if (response.data) {
          return response.data
        }
      } catch (e) {
        throw e;
      }

    },
    async deleteShareLink(shouhinId, officialOptionId, suuryoSentakuId) {
      try {
        const response = await axios.delete(this.serverPath + `/api/Distribution/distributionInfo/${shouhinId}/${officialOptionId}/${suuryoSentakuId}`)
        if(response){
          return response
        }
      } catch (error) {
        throw error
      }
    },
    performDistribution(shouhinId, officialOptionId, suuryoSentakuId, hash,characterId) {
      try{
        const response = axios.post(
          this.serverPath + `/api/Distribution/distribution`,
          {
            shouhinId: shouhinId,
            officialOptionId: officialOptionId,
            suuryoSentakuId: suuryoSentakuId,
            hash: hash,
            characterId:characterId,
            distributionType:0 // 0:URL配布 1:キャラクター配布
          }
        )
        return response;
      }catch (error) {
        throw error
      }
    },


    ///getDetail/{shouhinId}/{officialOptionId}/{suuryoSentakuId}
    async getDetailShareInfo(shouhinId, officialOptionId, suuryoSentakuId,hash) {
      try {
        const response = await axios.get(this.serverPath + `/api/Distribution/getDetail/${shouhinId}/${officialOptionId}/${suuryoSentakuId}`+`?hash=${hash}`)
        return response.data
      } catch (error) {
        throw error
      }
    },
  },
};
