import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// vuetify
import vuetify from './plugins/vuetify'
//veeValidate
import veeValidate from './plugins/vee-validate'
//axios
import axios from './plugins/axios'
//firebase
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
//firestorePlugin
import {firestorePlugin} from 'vuefire'
//VueReCaptcha
import {VueReCaptcha} from 'vue-recaptcha-v3'
//GoogleAdsense
import Ads from 'vue-google-adsense'
//サニタイズ
import DOMPurify from 'dompurify'
//masonry　ギャラリーで使用
import {VueMasonryPlugin} from 'vue-masonry'
//globalValiables
import gv from './mixins/globalValiables'
import tailorDistributionMixin   from "@/mixins/tailorDistributionMixin";
import wv from './mixins/worldValiables'
import events from './mixins/eventPage'
import mx from './mixins/mixin'
import parameterCalc from '@/mixins/parameterCalc'
import newScenarioApis from './mixins/newScenarioMethodsAndEnums'
import noteTagMixin from "@/mixins/noteTagMixin";
import app_global from '@/api/app_global.js'
import tailor_global from '@/api/tailor_global.js'
import Router from 'vue-router'

Vue.config.productionTip = false

Vue.use(vuetify)

Vue.use(veeValidate)

Vue.use(axios)

const config = {
  apiKey: "AIzaSyC90bW2mj1a9XrMi_tI75zG0GCF3flI5kk",
  authDomain: "alpaca-mp-staging.firebaseapp.com",
  databaseURL: "https://alpaca-mp-staging.firebaseio.com",
  projectId: "alpaca-mp-staging",
  storageBucket: "alpaca-mp-staging.appspot.com",
  messagingSenderId: "591681795132",
  appId: "1:591681795132:web:8ff2de8648bca9ebd27f39",
  measurementId: "G-QLVDME0B8G",
};
firebase.initializeApp(config);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

//router.pushのDuplicatedを回避
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {

  return originalPush.call(this, location).catch((err) => {
    if (err && err.name !== "NavigationDuplicated") {
      throw err;
    }
  });
}

export const db = firebase.firestore()
db.FieldValue = firebase.firestore.FieldValue

Vue.use(firestorePlugin)

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
  loaderOptions: {
    useRecaptchaNet: true
  },
  callback: function () {
    console.log('Verified: not robot')
  }
})

Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

Vue.use(DOMPurify)

Vue.use(VueMasonryPlugin)

Vue.mixin(gv)
Vue.mixin(wv)
Vue.mixin(tailorDistributionMixin)
Vue.mixin(noteTagMixin)
Vue.mixin(mx)
Vue.mixin(events)
Vue.mixin(parameterCalc)
Vue.mixin(newScenarioApis)

//ヒント表示用コンポーネント
const Hint = () => import(/* webpackChunkName: "tools" */ '@/components/Hint')
Vue.component('Hint', Hint)

//テキスト表示用コンポーネント
const BaseText = () =>
  import(/* webpackChunkName: "tools" */ '@/components/BaseText')
Vue.component('BaseText', BaseText)
const BaseIcon = () =>
  import(/* webpackChunkName: "tools" */ '@/components/BaseIcon')
Vue.component('BaseIcon', BaseIcon)

//ローディングコンポーネント
const Loading = () =>
  import(/* webpackChunkName: "tools" */ '@/components/Loading')
const UpLoading = () =>
  import(/* webpackChunkName: "tools" */ '@/components/UpLoading')
Vue.component('Loading', Loading)
Vue.component('UpLoading', UpLoading)

Vue.prototype.app_global = app_global

Vue.prototype.tailor_global = tailor_global

new Vue({
  components: {},
  router,
  store,
  vuetify,
  veeValidate,

  render: (h) => h(App)
}).$mount('#app')
