export default {
  data() {
    return {
      // serverPath:バックエンドサーバ
      serverPath: "https://api.alpaca-connect-stg.com",
      // rootPath:フロントエンドサーバ
      rootPath: "https://alpaca-connect-stg.com",
      // assetsPath:アセットサーバー
      assetsPath: "https://assets.alpaca-connect.com",
      // objectPath:オブジェクトサーバー
      objectPath: "https://object.alpaca-connect-stg.com",
      // novelPicker
      novelPickPath: "https://novelpick.alpaca-connect.com"
    };
  },
};
