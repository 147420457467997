import axios from 'axios'
import qs from 'qs'

const ScenarioStatus = {
  BeforeRelease: 'BeforeRelease',
  Released: 'Released',
  Writing: 'Writing',
  ReplayCompleted: 'ReplayCompleted',
  Cancel: 'Cancel'
}

const ScenarioResult = {
  NotYet: 'NotYet',
  GreatSuccess: 'GreatSuccess',
  Success: 'Success',
  Normal: 'Normal',
  Failure: 'Failure',
  GreatFailure: 'GreatFailure'
}

const OrderStatus = {
  PreOrder: 'PreOrder',
  Ordering: 'Ordering',
  Inviting: 'Inviting',
  CheckingByDirector: 'CheckingByDirector',
  CheckingByMaster: 'CheckingByMaster',
  OpenRequesting: 'OpenRequesting',
  Ordered: 'Ordered',
  Released: 'Released',
  Cancel: 'Cancel'
}

const ScenarioType = {
  Normal: 'Normal',
  React: 'React',
  Omnibus: 'Omnibus',
  RequestScenario: 'RequestScenario'
}

const RewardNo = {
  Non: 'Non',
  Low: 'Low',
  Normal: 'Normal',
  High: 'High'
}

const DifficultyNo = {
  Easy: 'Easy',
  Normal: 'Normal',
  Hard: 'Hard',
  VeryHard: 'VeryHard'
}

const ScenarioTextType = {
  Normal: 'Normal',
  Middle: 'Middle',
  Long: 'Long'
}

// 各Enumに対する日本語訳の辞書
const ScenarioStatusDescriptions = {
  [ScenarioStatus.BeforeRelease]: 'リリース前',
  [ScenarioStatus.Released]: 'リリース済み',
  [ScenarioStatus.Writing]: '執筆中',
  [ScenarioStatus.ReplayCompleted]: 'リプレイ完了',
  [ScenarioStatus.Cancel]: 'キャンセル'
}

const ScenarioResultDescriptions = {
  // [ScenarioResult.NotYet]: "未実施",
  [ScenarioResult.GreatSuccess]: '大成功',
  [ScenarioResult.Success]: '成功',
  [ScenarioResult.Normal]: '普通',
  [ScenarioResult.Failure]: '失敗',
  [ScenarioResult.GreatFailure]: '大失敗'
}

const OrderStatusDescriptions = {
  [OrderStatus.PreOrder]: '発注前処理',
  [OrderStatus.Ordering]: '発注枠確保状態',
  [OrderStatus.Inviting]: '招待中',
  [OrderStatus.CheckingByDirector]: 'Director確認中',
  [OrderStatus.CheckingByMaster]: 'Master確認中',
  [OrderStatus.OpenRequesting]: '発注リクエスト中',
  [OrderStatus.Ordered]: '発注確定',
  [OrderStatus.Released]: 'リリース完了',
  [OrderStatus.Cancel]: 'キャンセル'
}

const ScenarioTypeDescriptions = {
  [ScenarioType.Normal]: '通常シナリオ',
  [ScenarioType.React]: 'リアクトシナリオ',
  [ScenarioType.Omnibus]: 'オムニバスシナリオ',
  [ScenarioType.RequestScenario]: 'リクエストシナリオ'
}

const RewardNoDescriptions = {
  [RewardNo.Non]: 'なし',
  [RewardNo.Low]: '低い',
  [RewardNo.Normal]: '普通',
  [RewardNo.High]: '多い'
}

const DifficultyNoDescriptions = {
  [DifficultyNo.Easy]: '簡単',
  [DifficultyNo.Normal]: '普通',
  [DifficultyNo.Hard]: '難しい',
  [DifficultyNo.VeryHard]: 'とても難しい'
}

const ScenarioTextTypeDescriptions = {
  [ScenarioTextType.Normal]: 'ノーマル',
  [ScenarioTextType.Middle]: 'ミドル',
  [ScenarioTextType.Long]: 'ロング'
}

export {
  ScenarioStatus,
  ScenarioResult,
  OrderStatus,
  ScenarioType,
  RewardNo,
  DifficultyNo,
  ScenarioTextType,
  ScenarioStatusDescriptions,
  ScenarioResultDescriptions,
  OrderStatusDescriptions,
  ScenarioTypeDescriptions,
  RewardNoDescriptions,
  DifficultyNoDescriptions,
  ScenarioTextTypeDescriptions
}

export default {
  data() {
    return {
      ScenarioStatus: {
        BeforeRelease: 'BeforeRelease',
        Released: 'Released',
        Writing: 'Writing',
        ReplayCompleted: 'ReplayCompleted',
        Cancel: 'Cancel'
      },
      DifficultyNo: {
        Easy: 'Easy',
        Normal: 'Normal',
        Hard: 'Hard',
        VeryHard: 'VeryHard'
      },
      RewardNo: {
        Non: 'Non',
        Low: 'Low',
        Normal: 'Normal',
        High: 'High'
      },
      ScenarioType: {
        Normal: 'Normal',
        React: 'React',
        Omnibus: 'Omnibus',
        RequestScenario: 'RequestScenario'
      },
      OrderStatus: {
        PreOrder: 'PreOrder',
        Ordering: 'Ordering',
        Inviting: 'Inviting',
        CheckingByDirector: 'CheckingByDirector',
        CheckingByMaster: 'CheckingByMaster',
        OpenRequesting: 'OpenRequesting',
        Ordered: 'Ordered',
        Released: 'Released',
        Cancel: 'Cancel'
      },
      ScenarioResult: {
        NotYet: 'NotYet',
        GreatSuccess: 'GreatSuccess',
        Success: 'Success',
        Normal: 'Normal',
        Failure: 'Failure',
        GreatFailure: 'GreatFailure'
      },
      ScenarioTextType: {
        Normal: 'Normal',
        Middle: 'Middle',
        Long: 'Long'
      },
      ScenarioTextTypeDescriptions: {
        Normal: 'ノーマル',
        Middle: 'ミドル',
        Long: 'ロング',
        DecisiveBattle: '決戦'
      },
      //      DifficultyNoDescriptions: {
      //         Easy: '易しい',
      //         Normal: '普通',
      //         Hard: '難しい',
      //         VeryHard: '非常に難しい'
      //       },
      DifficultyMaster: {
        '0': {
          text: '易しい',
          value: 'Easy'
        },
        '1': {
          text: '普通',
          value: 'Normal'
        },
        '2': {
          text: '難しい',
          value: 'Hard'
        },
        '3': {
          text: '非常に難しい',
          value: 'VeryHard'
        }
      },
      RewardNoMaster: {
        '0': {
          text: 'なし',
          value: 'Non'
        },
        '1': {
          text: '少ない',
          value: 'Low'
        },
        '2': {
          text: '標準',
          value: 'Normal'
        },
        '3': {
          text: '多い',
          value: 'High'
        }
      },
      TextTypeMaster: {
        '0': {
          text: 'ノーマル',
          value: 'Normal'
        },
        '1': {
          text: 'ミドル',
          value: 'Middle'
        },
        '2': {
          text: 'ロング',
          value: 'Long'
        },
        '3': {
          text: '決戦',
          value: 'DecisiveBattle'
        }
      },
      DifficultyNoDescriptions: {
        Easy: '易しい',
        Normal: '普通',
        Hard: '難しい',
        VeryHard: '非常に難しい'
      },
      RewardNoDescriptions: {
        Non: 'なし',
        Low: '少ない',
        Normal: '標準',
        High: '多い'
      },
      ScenarioStatusDescriptions: {
        BeforeRelease: 'リリース前',
        Released: 'リリース済み',
        Writing: '執筆中',
        ReplayCompleted: 'リプレイ完了',
        Cancel: 'キャンセル'
      },
      ScenarioResultDescriptions: {
        NotYet: '未実施',
        GreatSuccess: '大成功',
        Success: '成功',
        Normal: '普通',
        Failure: '失敗',
        GreatFailure: '大失敗'
      }
    }
  },

  filters: {},

  methods: {
    ///api/ScenarioUtility/Master/getAssignedWorld
    getAssingedWorldListForMaster: async function () {
      let url =
        this.serverPath + `/api/ScenarioUtility/Master/getAssignedWorld`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },
    getScenarioTextTypeDescription: function () {
      return Object.keys(this.ScenarioTextType).map((key) => ({
        text: this.ScenarioTextTypeDescriptions[key],
        value: this.ScenarioTextType[key]
      }))
    },
    getDifficultyNoDescription: function () {

      return Object.keys(this.DifficultyNo).map((key) => ({
        text: this.DifficultyNoDescriptions[key],
        value: this.DifficultyNo[key]
      }))
    },
    getDifficultyNoDescriptionForSelect: function (difficultyNoList) {
      //difficultyNoListは,区切りの文字列
      //difficultyMasterを参照する
      //0,1,2,3...という形で記述されているので、textを取得してobjectに格納
      difficultyNoList = difficultyNoList.split(',')
      const arr = []
      difficultyNoList.forEach((difficultyNo) => {
        arr.push(this.DifficultyMaster[difficultyNo])
      })
      return arr
    },
    //rewardNoのdescriptionを取得 forSelect
    getRewardNoDescriptionForSelect: function (rewardNoList) {
      //rewardNoListは,区切りの文字列
      //rewardMasterを参照する
      //0,1,2,3...という形で記述されているので、textを取得してobjectに格納
      rewardNoList = rewardNoList.split(',')
      const arr = []
      rewardNoList.forEach((rewardNo) => {
        arr.push(this.RewardNoMaster[rewardNo])
      })
      return arr
    },

    //textTypeのdescriptionを取得 forSelect
    getTextTypeDescriptionForSelect: function (textTypeList) {
      //textTypeListは,区切りの文字列
      //scenarioTextTypeを参照する
      //Normal,Middle,Long...という形で記述されているので、textを取得してobjectに格納
      textTypeList = textTypeList.split(',')
      const arr = []
      textTypeList.forEach((textType) => {
        arr.push(this.TextTypeMaster[textType])
      })
      return arr
    },

    getRewardNoDescription: function () {
      return Object.keys(this.RewardNo).map((key) => ({
        text: this.RewardNoDescriptions[key],
        value: this.RewardNo[key]
      }))
    },
    getScenarioTypeDescription: function () {
      return Object.keys(this.ScenarioType).map((key) => ({
        text: this.ScenarioTypeDescriptions[key],
        value: this.ScenarioType[key]
      }))
    },

    getScenarioTypeText(type) {
      if (type === 'Omnibus') {
        return 'オムニバス'
      } else if (type === 'React') {
        return 'リアクト'
      } else if (type === 'Normal') {
        return 'ノーマル'
      } else if (type === 'RequestScenario') {
        return 'リクエスト'
      }
    },
    getOrderStatusDescription: function () {
      return Object.keys(this.OrderStatus).map((key) => ({
        text: this.OrderStatusDescriptions[key],
        value: this.OrderStatus[key]
      }))
    },
    getScenarioResultDescription: function () {
      const arr = Object.keys(this.ScenarioResult).map((key, i) => ({
        text: this.ScenarioResultDescriptions[key],
        value: i
      }))
      return arr.splice(1, 5)
    },
    getScenarioStatusDescription: function () {
      return Object.keys(this.ScenarioStatus).map((key) => ({
        text: this.ScenarioStatusDescriptions[key],
        value: this.ScenarioStatus[key]
      }))
    },

    //リアクトadmin用
    ///api/ScenarioReact/admin/getList/{worldId}
    getScenarioReactListForAdmin: async function (worldId, page, perPage) {
      //page,perPageは任意。あればqueryに追加
      let query = ''
      if (page != null) {
        query += `page=${page}&`
      }
      if (perPage) {
        query += `perPage=${perPage}&`
      }
      if (query) {
        query = '?' + query
      }

      let url =
        this.serverPath + `/api/ScenarioReact/admin/getList/${worldId}${query}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },

    //get
    ///api/ScenarioReact/admin/get/{worldId}/{templateId}
    getScenarioReactForAdmin: async function (worldId, templateId) {
      let url =
        this.serverPath +
        `/api/ScenarioReact/admin/get/${worldId}/${templateId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },

    //createScenarioReactTemplate
    // /api/ScenarioReact/admin
    createScenarioReactTemplate: async function (
      scenarioReactMaster,
      characterRelationList
    ) {
      let url = this.serverPath + `/api/ScenarioReact/admin`

      const body = {
        scenarioReactMaster: scenarioReactMaster,
        characterRelationList: characterRelationList
      }

      let response = await axios.post(url, body)
      if (response.data) {
        return response.data
      }
    },
    //updateScenarioReactTemplate
    //PUTメソッド
    // /api/ScenarioReact/admin
    updateScenarioReactTemplate: async function (
      scenarioReactMaster,
      characterRelationList
    ) {
      let url = this.serverPath + `/api/ScenarioReact/admin`

      const body = {
        scenarioReactMaster: scenarioReactMaster,
        characterRelationList: characterRelationList
      }

      let response = await axios.put(url, body)
      if (response.data) {
        return response.data
      }
    },

    //deleteScenarioReactTemplate
    //DELETEメソッド
    // /api/ScenarioReact/admin/{worldId}/{templateId}
    deleteScenarioReactTemplate: async function (worldId, templateId) {
      let url =
        this.serverPath + `/api/ScenarioReact/admin/${worldId}/${templateId}`

      let response = await axios.delete(url)
      if (response.data) {
        return response.data
      }
    },

    //master用処理
    //GET /api/ScenarioReact/master/getList/{worldId}
    /*
    クエリパラメータ
      page: ページ番号
      perPage: 1ページあたりの表示件数
     */
    getScenarioReactListForMaster: async function (worldId, page, perPage) {
      //page,perPageは任意。あればqueryに追加
      let query = ''
      if (page != null) {
        query += `page=${page}&`
      }
      if (perPage) {
        query += `perPage=${perPage}&`
      }
      if (query) {
        query = '?' + query
      }

      let url =
        this.serverPath +
        `/api/ScenarioReact/master/getList/${worldId}${query}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },
    //GET /api/ScenarioReact/master/get/{worldId}/{templateId}
    getScenarioReactForMaster: async function (worldId, templateId) {
      let url =
        this.serverPath +
        `/api/ScenarioReact/master/get/${worldId}/${templateId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },
    //GET /api/ScenarioReact/master/orderSetting
    getOrderSettingForMaster: async function () {
      let url = this.serverPath + `/api/ScenarioReact/master/orderSetting`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },

    //POST /api/ScenarioReact/master/orderSetting
    postOrderSettingForMaster: async function (scenarioReactOrderSettingList) {
      const body = {
        scenarioReactOrderSettingList: scenarioReactOrderSettingList
      }
      let url = this.serverPath + `/api/ScenarioReact/master/orderSetting`
      let response = await axios.post(url, body)
      if (response.data) {
        return response.data
      }
    },

    //ユーザー向けリアクトAPI
    //GET /api/ScenarioReact/getReactList/past/{worldId}
    getReactListPast: async function (worldId) {
      let url =
        this.serverPath + `/api/ScenarioReact/getReactList/past/${worldId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },

    //現在参加可能なリアクトシナリオの一覧を取得
    //GET /api/ScenarioReact/getReactList/current/{worldId}
    getReactListCurrent: async function (worldId) {
      let url =
        this.serverPath + `/api/ScenarioReact/getReactList/current/${worldId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },

    //公開されているリアクトの詳細を取得
    //GET /api/ScenarioReact/getReactDetail/{worldId}/{templateId}
    getReactDetail: async function (worldId, templateId) {
      let url =
        this.serverPath +
        `/api/ScenarioReact/getReactDetail/${worldId}/${templateId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },

    async getMasterListForScenarioRequest() {

      const res = await axios.get(
        this.serverPath + `/api/ScenarioRequest/getMasterList/${this.$store.state.worldId}`
      )

      if (res.data) {
        return res.data.masterList
      }

    },
    //各テンプレートごとに受注設定しているマスターの一覧を取得します
    //GET /api/ScenarioReact/getMasterList/{worldId}/{templateId}
    getMasterList: async function (worldId, templateId) {
      let url =
        this.serverPath +
        `/api/ScenarioReact/getMasterList/${worldId}/${templateId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },

    //GET /api/ScenarioReact/getMasterList/{worldId}
    //ワールド全体のリアクト-マスターの一覧を取得します
    getMasterListAll: async function (worldId) {
      let url = this.serverPath + `/api/ScenarioReact/getMasterList/${worldId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },
    ///api/ScenarioOrder/cancelOrder
    //orderId
    cancelOrder(orderId) {
      const body = {
        orderId: orderId
      }
      let url = this.serverPath + `/api/ScenarioOrder/cancelOrder`
      return axios.post(url, body)
    },

    confirmOrderByOwner: async function (orderId) {
      let url = this.serverPath + `/api/ScenarioOrder/confirmOrder`
      const body = {
        orderId: orderId
      }
      let response = await axios.put(url, body)
      if (response.data) {
        return response.data
      }
    },

    //リクエストシナリオの発注をする
    orderRequestScenario: async function (
      masterId,
      characterId,
      worldId,
      orderCoin,
      purpose,
      orderContent,
      textType,
      relatedScenario1,
      relatedScenario2
    ) {
      const body = {
        masterId: masterId,
        characterId: characterId,
        worldId: worldId,
        orderCoin: orderCoin,
        purpose: purpose,
        orderContent: orderContent,
        textType: textType,
        relatedScenarioId1: relatedScenario1 ? Number(relatedScenario1) : null,
        relatedScenarioId2: relatedScenario2 ? Number(relatedScenario2) : null
      }
      let url = this.serverPath + `/api/ScenarioRequest/order`
      return await axios.post(url, body)
    },

    //リアクトのオーダーをする
    ///api/ScenarioReact/order
    //cenarioReactRequest オブジェクト。オブジェクトは以下のパラメーターを含む:
    //
    // worldId: ワールドの ID。 (String)
    // templateId: テンプレートの ID。 (Integer)
    // masterId: マスターの ID。 (Integer)
    // characterId: キャラクターの ID。 (Integer)
    // writingPeriod: 執筆期間。 (Integer)
    // inviteFlg: 招待フラグ。 (Boolean)
    orderReactScenario: async function (
      worldId,
      templateId,
      masterId,
      characterId,
      inviteFlg
    ) {
      const body = {
        worldId: worldId,
        templateId: templateId,
        masterId: masterId,
        characterId: characterId,
        inviteFlg: inviteFlg
      }
      let url = this.serverPath + `/api/ScenarioReact/order`
      return axios.post(url, body)
    },

    //テキストタイプに応じて、コインリストを返す
    getCoinListForRequest: function (textType) {
      const coinList = []
      if (textType === 'Normal') {
        for (let i = 0; i <= 13; i++) {
          coinList.push(1300 + 100 * i)
        }
      } else if (textType === 'Middle') {
        for (let i = 0; i <= 18; i++) {
          coinList.push(1800 + 100 * i)
        }
      } else if (textType === 'Long') {
        for (let i = 0; i <= 23; i++) {
          coinList.push(2300 + 100 * i)
        }
      }

      return coinList
    },

    //ここからオーダー系
    // APIの基本エンドポイントは /api/ScenarioOrder です。
    //
    // 1. getOrderList
    // エンドポイント: /getOrderList
    getOrderList: async function (page, perPage) {
      let url = this.serverPath + `/api/ScenarioOrder/getOrderList`
      let query = ''
      if (page != null) {
        query += `page=${page}&`
      }
      if (perPage) {
        query += `perPage=${perPage}&`
      }
      if (query) {
        query = '?' + query
      }
      url += query
      const res = await axios.get(url)
      return res.data
    },

    // 2. getOrderDetail
    // エンドポイント: /getOrderDetail/{orderId}
    // メソッド: GET
    // パスパラメータ:
    // リクエストパラメータ:
    // hash (必須ではない): ハッシュ値。該当シナリオのhash値。主発注者以外が見るために必要
    getOrderDetail: async function (orderId, hash) {
      let url =
        this.serverPath + `/api/ScenarioOrder/getOrderDetail/${orderId}`
      let query = ''
      if (hash) {
        query += `hash=${hash}&`
      }
      if (query) {
        query = '?' + query
      }
      url += query
      const res = await axios.get(url)
      return res.data
    },

    // getInvitingList
    // エンドポイント: /getInvitingList/{characterId}
    // メソッド: GET
    // パスパラメータ:
    // リクエストパラメータ:
    getInvitingList: async function (characterId) {
      let url =
        this.serverPath + `/api/ScenarioOrder/getInvitingList/${characterId}`
      const res = await axios.get(url)
      return res.data
    },

    // 3. participation
    // エンドポイント: /participation
    // メソッド: POST
    // リクエストボディ (JSON)
    // {
    //     "orderId": "エクササイズのパラメーター名",
    //     "characterId": "エクササイズのパラメーター名",
    //     "hash": "エクササイズのパラメーター名"
    // }
    participationForScenarioOrder: async function (orderId, characterId, hash) {
      const body = {
        orderId: orderId,
        characterId: characterId,
        hash: hash
      }
      let url = this.serverPath + `/api/ScenarioOrder/participation`
      return await axios.post(url, body)
    },

    // 4. participation
    // エンドポイント: /participation
    // メソッド: PUT
    // リクエストボディ (JSON)
    // {
    //     "orderId": "エクササイズのパラメーター名",
    //     "beforeCharacterId": "エクササイズのパラメーター名",
    //     "afterCharacterId": "エクササイズのパラメーター名"
    // }
    changeCharacterForScenarioOrder: async function (
      orderId,
      beforeCharacterId,
      afterCharacterId
    ) {
      const body = {
        orderId: orderId,
        beforeCharacterId: beforeCharacterId,
        afterCharacterId: afterCharacterId
      }
      let url = this.serverPath + `/api/ScenarioOrder/participation`
      return await axios.put(url, body)
    },
    // 5. getSubOrderList
    // 招待制で参加したシナリオとキャラクターの取得
    // エンドポイント: /getSubOrderList
    // メソッド: GET
    // リクエストパラメータ:
    // page (必須ではない): ページングのページ数
    // perPage (必須ではない): ページあたりのシナリオの数
    getSubOrderList: async function (page, perPage) {
      let url = this.serverPath + `/api/ScenarioOrder/getSubOrderList`
      let query = ''
      if (page != null) {
        query += `page=${page}&`
      }
      if (perPage) {
        query += `perPage=${perPage}&`
      }
      if (query) {
        query = '?' + query
      }
      url += query
      const res = await axios.get(url)
      return res.data
    },

    //ここからオムニバスAdmin関係
    //. Get Scenario Omnibus Template Master
    // Path: /api/ScenarioOmnibus/admin/template/getList/{worldId}
    // HTTP Method: GET
    // Description: Retrieves a list of Scenario Omnibus Template Masters
    // page (Query Parameter, Optional): Page number for pagination
    // perPage (Query Parameter, Optional): Number of records per page for pagination
    // Response: ScenarioOmnibusAdminResponse
    getScenarioOmnibusTemplateMasterList: async function (
      worldId,
      page,
      perPage
    ) {
      let url =
        this.serverPath +
        `/api/ScenarioOmnibus/admin/template/getList/${worldId}`
      let query = ''
      if (page != null) {
        query += `page=${page}&`
      }
      if (perPage) {
        query += `perPage=${perPage}&`
      }
      if (query) {
        query = '?' + query
      }
      url += query
      const res = await axios.get(url)
      return res.data
    },
    // 2. Create Scenario Omnibus Template Master
    // Path: /api/ScenarioOmnibus/admin/template
    // HTTP Method: POST
    // Description: Creates a Scenario Omnibus Template Master
    // Request Body:
    // ScenarioOmnibusAdminRequest
    // Response: HttpStatus CREATED
    createScenarioOmnibusTemplateMaster: async function (
      scenarioOmnibusTemplateMaster
    ) {
      const body = {
        scenarioOmnibusTemplateMaster: scenarioOmnibusTemplateMaster
      }
      let url = this.serverPath + `/api/ScenarioOmnibus/admin/template`
      return await axios.post(url, body)
    },

    // 3. Update Scenario Omnibus Template Master
    // Path: /api/ScenarioOmnibus/admin/template
    // HTTP Method: PUT
    // Description: Updates a Scenario Omnibus Template Master
    // Response: HttpStatus CREATED
    updateScenarioOmnibusTemplateMaster: async function (
      scenarioOmnibusTemplateMaster
    ) {
      const body = {
        scenarioOmnibusTemplateMaster: scenarioOmnibusTemplateMaster
      }
      let url = this.serverPath + `/api/ScenarioOmnibus/admin/template`
      return await axios.put(url, body)
    },

    // 4. Delete Scenario Omnibus Template Master
    // Path: /api/ScenarioOmnibus/admin/template/{worldId}/{templateId}
    // HTTP Method: DELETE
    // Description: Deletes a Scenario Omnibus Template Master
    // Request Parameters:
    // worldId (Path Variable): The worldId to delete Scenario Omnibus Template Masters
    // templateId (Path Variable): The templateId to delete Scenario Omnibus Template Masters
    // Response: HttpStatus OK
    deleteScenarioOmnibusTemplateMaster: async function (worldId, templateId) {
      let url =
        this.serverPath +
        `/api/ScenarioOmnibus/admin/template/${worldId}/${templateId}`
      return await axios.delete(url)
    },

    // 5. Get Review List
    // Path: /api/ScenarioOmnibus/admin/getReviewList/{worldId}
    // HTTP Method: GET
    // Description: Retrieves a list of reviews
    // Request Parameters:
    // worldId (Path Variable)
    // Response: ScenarioOmnibusAdminResponse
    getReviewList: async function (worldId) {
      let url =
        this.serverPath + `/api/ScenarioOmnibus/admin/getReviewList/${worldId}`
      const res = await axios.get(url)
      return res.data
    },

    // 6. Get Review Detail
    // Path: /api/ScenarioOmnibus/admin/getReviewDetail/{omnibusId}
    // HTTP Method: GET
    // Description: Retrieves review detail for a specific omnibusId
    // Request Parameters:
    // omnibusId (Path Variable)
    // Response: ScenarioOmnibusAdminResponse
    getReviewDetail: async function (omnibusId) {
      let url =
        this.serverPath +
        `/api/ScenarioOmnibus/admin/getReviewDetail/${omnibusId}`
      const res = await axios.get(url)
      return res.data
    },

    // 7. Post Review Result
    // Path: /api/ScenarioOmnibus/admin/reviewResult
    // HTTP Method: POST
    // Description: Posts review results, status and comments
    // Request Body:
    //  omnibusId
    //  status
    //  comment
    // Response: HttpStatus OK
    postReviewResult: async function (omnibusId, status, comment) {
      const body = {
        omnibusId: omnibusId,
        status: status, //ShinseiStatusです
        comment: comment
      }
      let url = this.serverPath + `/api/ScenarioOmnibus/admin/reviewResult`
      return await axios.post(url, body)
    },

    // 8. Update Omnibus Info
    // Path: /api/ScenarioOmnibus/admin/updateOmnibusInfo
    // HTTP Method: PUT
    // Description: Updates OmnibusInfo
    // Request Body:
    // ScenarioOmnibusAdminRequest
    // Response: HttpStatus OK
    updateOmnibusInfo: async function (scenarioOmnibusInfo, characterList) {
      const body = {
        scenarioOmnibusInfo: scenarioOmnibusInfo,
        characterList: characterList
      }
      let url =
        this.serverPath + `/api/ScenarioOmnibus/admin/updateOmnibusInfo`
      return await axios.put(url, body)
    },

    //ここからオムニバスでマスター用
    //GET /api/ScenarioOmnibus/master/template/list/{worldId}
    // {worldId}: 取得したいテンプレートリストが属するワールドのID。
    // past(オプション): Trueの場合、過去のテンプレートを取得します。
    // current(オプション): Trueの場合、現在のテンプレートを取得します。
    getOmnibusTemplateForMaster: async function (worldId, past, current) {
      let url =
        this.serverPath +
        `/api/ScenarioOmnibus/master/template/list/${worldId}`
      let query = ''
      if (past) {
        query += `past=${past}&`
      }
      if (current) {
        query += `current=${current}&`
      }

      if (query) {
        query = '?' + query
      }
      url += query
      const res = await axios.get(url)
      return res.data
    },
    // GET /api/ScenarioOmnibus/master/template/{worldId}/{templateId}
    // {worldId}: テンプレートが属するワールドのID。
    // {templateId}: 取得したいテンプレートのID。
    getOmnibusTemplateDetailForMaster: async function (worldId, templateId) {
      let url =
        this.serverPath +
        `/api/ScenarioOmnibus/master/template/${worldId}/${templateId}`
      const res = await axios.get(url)
      return res.data
    },

    // POST /api/ScenarioOmnibus/master/omnibusInfo/create
    // リクエストボディ: 新たに作成するオムニバス情報 (ScenarioOmnibusRequest オブジェクト)。
    createOmnibusInfoForMaster: async function (
      scenarioOmnibusInfo,
      characterList
    ) {
      const body = {
        scenarioOmnibusInfo: scenarioOmnibusInfo,
        relatedCharacterList: characterList
      }
      let url =
        this.serverPath + `/api/ScenarioOmnibus/master/omnibusInfo/create`
      return await axios.post(url, body)
    },

    // put /api/ScenarioOmnibus/master/omnibusInfo/update
    // リクエストボディ: 更新したいオムニバス情報 (ScenarioOmnibusRequest オブジェクト)。
    updateOmnibusInfoForMaster: async function (
      scenarioOmnibusInfo,
      characterList
    ) {
      const body = {
        scenarioOmnibusInfo: scenarioOmnibusInfo,
        relatedCharacterList: characterList
      }
      let url =
        this.serverPath + `/api/ScenarioOmnibus/master/omnibusInfo/update`
      return await axios.put(url, body)
    },

    // GET /api/ScenarioOmnibus/master/omnibusInfo/getScenarioListForOrder このエンドポイントはパラメータを受け取りません。
    getScenarioListForOrder: async function () {
      let url =
        this.serverPath +
        `/api/ScenarioOmnibus/master/omnibusInfo/getScenarioListForOrder`
      const res = await axios.get(url)
      return res.data
    },

    // POST /api/ScenarioOmnibus/master/omnibusInfo/orderSetting
    // リクエストボディ: 設定したいシナリオのオーダー情報 (ScenarioOmnibusRequest オブジェクト)。
    postOrderSettingForOmnibus: async function (settingInfoList) {
      const body = {
        settingInfoList: settingInfoList
      }
      let url =
        this.serverPath +
        `/api/ScenarioOmnibus/master/omnibusInfo/orderSetting`
      return await axios.post(url, body)
    },

    // GET /api/ScenarioOmnibus/master/omnibusInfo/list/{worldId}
    // {worldId}: オムニバス一覧の属するワールドのID。
    getOmnibusInfoListMineForMaster: async function (worldId, past, current) {
      let url =
        this.serverPath +
        `/api/ScenarioOmnibus/master/omnibusInfo/list/${worldId}`

      let query = ''
      if (past) {
        query += `past=${past}&`
      }
      if (current) {
        query += `current=${current}&`
      }
      if (query) {
        query = '?' + query
      }
      url += query
      const res = await axios.get(url)
      return res.data
    },

    ///api/ScenarioOmnibus/getOmnibusList/{worldId}
    // HTTP Method: GET
    // Description: Fetch a list of omnibuses that can be viewed in the current theme that have been released.
    // Request Parameters:
    // worldId (path)
    // Response: ScenarioOmnibusResponse, contains a list of omnibuses.
    getOmnibusList: async function (worldId) {
      let url =
        this.serverPath + `/api/ScenarioOmnibus/getOmnibusList/${worldId}`
      const res = await axios.get(url)
      return res.data
    },

    ///getOnGoingRequestScenarioList/{characterId}
    // HTTP Method: GET
    getOngoingRequestScenarioList: async function (characterId) {
      let url =
        this.serverPath +
        `/api/ScenarioOrder/getOnGoingRequestScenarioList/${characterId}`
      const res = await axios.get(url)
      return res.data
    },

    // /api/ScenarioOmnibus/getOmnibusDetail/{omnibusId}
    // HTTP Method: GET
    // Description: Fetch the details of a released omnibus.
    // Request Parameters:
    // omnibusId (path)
    // Response: ScenarioOmnibusResponse, contains the omnibus details.
    getOmnibusDetail: async function (omnibusId) {
      let url =
        this.serverPath + `/api/ScenarioOmnibus/getOmnibusDetail/${omnibusId}`
      const res = await axios.get(url)
      return res.data
    },

    // /api/ScenarioOmnibus/getMasterList/{worldId}/{omnibusThemeId}
    // HTTP Method: GET
    // Description: Fetch a list of masters for each template ID.
    // Request Parameters:
    // worldId (path)
    // omnibusThemeId (path)
    // Response: ScenarioOmnibusResponse, contains a list of omnibuses.
    getMasterListForOmnibusByThemeId: async function (worldId, omnibusThemeId) {
      let url =
        this.serverPath +
        `/api/ScenarioOmnibus/getMasterList/${worldId}/${omnibusThemeId}`
      const res = await axios.get(url)
      return res.data
    },

    // /api/ScenarioOmnibus/getRelatedOmnibusList/{worldId}/{omnibusId}
    // HTTP Method: GET
    // Description: Fetch a list of scenarios released in the related omnibus if any.
    // Request Parameters:
    // worldId (path)
    // omnibusId (path)
    // Response: ScenarioOmnibusResponse, contains a list of opening scenarios.
    getRelatedOmnibusList: async function (worldId, omnibusId) {
      let url =
        this.serverPath +
        `/api/ScenarioOmnibus/getRelatedOmnibusList/${worldId}/${omnibusId}`
      const res = await axios.get(url)
      return res.data
    },

    // /api/ScenarioOmnibus/order
    // HTTP Method: POST
    // Description: Execute an order.
    // Request Parameters:
    // JSON body - a ScenarioOmnibusRequest object
    // Response: HttpStatus of CREATED with ScenarioReactResponse.
    orderOmnibus: async function (worldId, characterId, omnibusId, inviteFlg) {
      const scenarioOmnibusRequest = {
        worldId: worldId,
        characterId: characterId,
        omnibusId: omnibusId,
        inviteFlg: inviteFlg
      }
      let url = this.serverPath + `/api/ScenarioOmnibus/order`
      return await axios.post(url, scenarioOmnibusRequest)
    },

    //ここからOP関係API
    // GET /api/v2/ScenarioOpening/getOpeningList
    // 目的: 公開されているシナリオの一覧を取得します。
    // リクエストパラメータ:
    // WorldTopRequest: ワールドID、ページサイズ、開始ページなどを含む。
    // レスポンス: ScenarioOpeningResponse - シナリオの一覧と総数を含む。
    getOpeningList: async function (worldId, pageStart, perPage) {
      let url = this.serverPath + `/api/v2/ScenarioOpening/getOpeningList`
      let query = ''

      if (worldId) {
        query += `worldId=${worldId}&`
      }
      if (pageStart != null) {
        query += `pageStart=${pageStart}&`
      }
      if (perPage) {
        query += `perPage=${perPage}&`
      }
      if (query) {
        query = '?' + query
      }
      url += query
      const res = await axios.get(url)
      return res.data
    },

    // GET /api/v2/ScenarioOpening/getWritingList
    // 目的: 執筆中のシナリオの一覧を取得します。
    // リクエストパラメータ: WorldTopRequest（上記参照）
    // レスポンス: ScenarioOpeningResponse（上記参照）
    getWritingList: async function (worldId, pageStart, perPage) {
      let url = this.serverPath + `/api/v2/ScenarioOpening/getWritingList`
      let query = ''
      if (worldId) {
        query += `worldId=${worldId}&`
      }
      if (pageStart != null) {
        query += `pageStart=${pageStart}&`
      }
      if (perPage) {
        query += `perPage=${perPage}&`
      }
      if (query) {
        query = '?' + query
      }
      url += query
      const res = await axios.get(url)
      return res.data
    },

    // GET /api/v2/ScenarioOpening/getReplayList
    // 目的: リプレイ完了したシナリオの一覧を取得します。
    // リクエストパラメータ: WorldTopRequest（上記参照）
    // レスポンス: ScenarioOpeningResponse（上記参照）
    getReplayList: async function (worldId, pageStart, perPage) {
      let url = this.serverPath + `/api/v2/ScenarioOpening/getReplayList`

      let query = ''

      if (worldId) {
        query += `worldId=${worldId}&`
      }

      if (pageStart != null) {
        query += `pageStart=${pageStart}&`
      }
      if (perPage) {
        query += `perPage=${perPage}&`
      }
      if (query) {
        query = '?' + query
      }
      url += query
      const res = await axios.get(url)
      return res.data
    },

    // GET /api/v2/ScenarioOpening/getScenarioDetail/{worldId}/{scenarioId}
    // 目的: 特定のシナリオの詳細情報を取得します。
    // リクエストパラメータ:
    // worldId: ワールドのID。
    // scenarioId: シナリオのID。
    // characterId (オプション): キャラクターID。
    // レスポンス: ScenarioOpeningResponse - シナリオの詳細、関連キャラクター、条件などを含む。

    getScenarioDetailWithOpening: async function (
      worldId,
      scenarioId,
      phase,
      characterId
    ) {
      let url =
        this.serverPath +
        `/api/v2/ScenarioOpening/getScenarioDetail/${worldId}/${scenarioId}/${phase}`
      let query = ''
      if (characterId) {
        query += `characterId=${characterId}&`
      }
      if (query) {
        query = '?' + query
      }
      url += query

      const res = await axios.get(url)
      return res.data
    },

    // POST /api/v2/ScenarioOpening/participation
    // 目的: 特定のシナリオに参加します。
    // リクエストボディ: ScenarioOpeningRequest
    // worldId: ワールドのID。
    // scenarioId: シナリオのID。
    // characterId: キャラクターID。
    // レスポンス: 作成成功時はHTTPステータスコード201を返します。
    participationForScenarioOpening: async function (
      worldId,
      scenarioId,
      phase,
      characterId
    ) {
      const body = {
        worldId: worldId,
        scenarioId: scenarioId,
        phase: phase,
        characterId: characterId
      }
      let url = this.serverPath + `/api/v2/ScenarioOpening/participation`
      return await axios.post(url, body)
    },

    //ここからリプレイ管理用
    //マスター用
    //ScenarioReplayManagementControllerは、シナリオリプレイ管理に関連する複数のエンドポイントを提供します。以下に、主要なエンドポイントの詳細を記載します。
    //
    // 1. シナリオリプレイ一覧検索 (/api/ScenarioReplayManagement/list)
    // メソッド: GET
    // 説明: 登録されているシナリオリプレイのうち、未申請・却下のものの一覧を取得します。
    // リクエストパラメータ: なし
    // レスポンス:
    // ScenarioReplayManagementResponse オブジェクト
    getScenarioReplayManagementList: async function (
      scenarioStatus,
      pageStart,
      perPage
    ) {
      let config
      config = {
        method: 'get',
        params: {
          scenarioStatus: scenarioStatus
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {arrayFormat: 'repeat'})
        },
        responseType: 'json'
      }

      let url = this.serverPath + `/api/ScenarioReplayManagement/list`
      let query = ''
      if (pageStart != null) {
        query += `pageStart=${pageStart}&`
      }
      if (perPage) {
        query += `perPage=${perPage}&`
      }
      if (query) {
        query = '?' + query
      }
      url += query

      const res = await axios.get(url, config)
      return res.data
    },
    // 2. シナリオリプレイ申請一覧検索 (/api/ScenarioReplayManagement/checkList)
    // メソッド: GET
    // 説明: シナリオリプレイのうち、申請中、再申請の申請一覧を取得します。
    // レスポンス:
    // ScenarioReplayManagementResponse オブジェクト
    getScenarioReplayManagementCheckList: async function () {
      let url = this.serverPath + `/api/ScenarioReplayManagement/checkList`
      const res = await axios.get(url)
      return res.data
    },

    // 3. シナリオ詳細検索 (/api/ScenarioReplayManagement/detail)
    // メソッド: GET
    // 説明: 指定されたシナリオの詳細情報を取得します。
    // リクエストパラメータ:
    // ShinarioReplayManageRequest オブジェクト
    // worldId (String): ワールドID
    // scenarioId (Integer): シナリオID
    // レスポンス:
    // ShinarioReplayManageResponse オブジェクト
    getScenarioReplayManagementDetail: async function (worldId, scenarioId, phase) {
      let url = this.serverPath + `/api/ScenarioReplayManagement/detail`
      const query = `worldId=${worldId}&scenarioId=${scenarioId}&phase=${phase}`
      url += '?' + query
      const res = await axios.get(url)
      return res.data
    },

    //
    // 4. シナリオリプレイ更新 (/api/ScenarioReplayManagement/updateScenario)
    // メソッド: POST
    // 説明: シナリオリプレイ情報を更新します。
    // リクエストパラメータ:
    // ShinarioReplayManageRequest オブジェクト
    // レスポンス:
    // HTTPステータスコード
    updateScenarioReplayManagement: async function (
      scenarioInfo,
      characterConditionListForUpdate,
      privateReplayList,
      mvpShogoCharacters,
      deleteItemList,
      isManagement,
      scenarioAppendInfo,
      phase
    ) {
      const scenarioReplayManagementRequest = {
        scenarioInfo: scenarioInfo,
        characterConditionListForUpdate: characterConditionListForUpdate,
        privateReplayList: privateReplayList,
        mvpShogoCharacters: mvpShogoCharacters,
        deleteItemList: deleteItemList,
        isManagement: isManagement,
        scenarioAppendInfo: scenarioAppendInfo,
        phase: phase
      }
      let url =
        this.serverPath + `/api/ScenarioReplayManagement/updateScenario`
      return await axios.post(url, scenarioReplayManagementRequest)
    },

    //updateScenarioCharacterRelation
    // /api/ScenarioInfo/admin/updateRelatedCharacter
    updateCharacterRelationForScenario: async function (worldId, scenarioId, characterRelationList) {

      const body = {
        worldId: worldId,
        scenarioId: scenarioId,
        relatedCharacterList: characterRelationList
      }

      try {
        return axios.post(this.serverPath + '/api/ScenarioInfo/admin/updateRelatedCharacter', body)
      } catch (error) {
        console.log(error)
      }

    },

    // シナリオリプレイ承認／却下
    updateScenarioByAdmin: async function (
      status,
      worldId,
      scenarioId,
      mvpShogoCharacters,
      comment,
      phase
    ) {
      const body = {
        status: status, //ShinseiStatusです
        worldId: worldId,
        scenarioId: scenarioId,
        mvpShogoCharacters: mvpShogoCharacters,
        comment: comment,
        phase: phase
      }
      let url =
        this.serverPath + `/api/ScenarioReplayManagement/updateScenarioByAdmin`
      return await axios.post(url, body)
    },

    // 5. CSVダウンロード (/api/ScenarioReplayManagement/downloadCSV/{worldId}/{scenarioId})
    // メソッド: GET
    // 説明: 指定されたシナリオのデータをCSV形式でダウンロードします。
    // リクエストパラメータ:
    // worldId (String): ワールドID
    // scenarioId (Integer): シナリオID
    // レスポンス:
    // CSVファイル

    downloadCSV: async function (worldId, scenarioId) {
      let url =
        this.serverPath +
        `/api/ScenarioReplayManagement/downloadCSV/${worldId}/${scenarioId}`

      await axios({
        method: 'get',
        // responseType: "blob",
        url: url
      }).then((res) => {
        const bom = new Uint8Array([0xef, 0xbb, 0xbf])

        const url = URL.createObjectURL(
          new Blob([bom, res.data], {type: 'text/csv'})
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `alpaca-connect_scenario${this.scenarioId}.csv`
        )
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(url)
        link.parentNode.removeChild(link)
      })
    },

    // downloadCSV: async function (worldId, scenarioId) {
    //   let url =
    //     this.serverPath +
    //     `/api/ScenarioReplayManagement/downloadCSV/${worldId}/${scenarioId}`;
    //   const res = await axios.get(url);
    //   return res.data;
    // },

    //ここからプレイング取得関係
    //GET /api/v2/ScenarioPlaying
    //
    // 説明：
    // プレイヤーに関する情報を取得します。
    // リクエストパラメータ：
    // ScenarioPlayingRequest オブジェクト
    //
    // public class ScenarioPlayingRequest {
    //   private String worldId;
    //   private Integer scenarioId;
    //   private String characterId;
    // }
    findPlayingByWorldIdAndScenarioIdAndCharacterId: async function (
      worldId,
      scenarioId,
      phase,
      characterId
    ) {
      let url = this.serverPath + `/api/v2/ScenarioPlaying`
      const query = `worldId=${worldId}&scenarioId=${scenarioId}&phase=${phase}&characterId=${characterId}`
      url += '?' + query
      const res = await axios.get(url)
      return res.data
    },

    // POST /api/v2/ScenarioPlaying/save
    // 説明：
    // プレイヤーの情報を保存します。
    // リクエストボディ：
    // ScenarioPlayingRequest オブジェクト
    //
    // worldId:string
    // scenarioId:number
    // characterId:string
    // chooseId:number
    // consumeItem:number
    // playing
    //
    // レスポンス：
    // EquipListResponse オブジェクト
    // ステータスコード：
    // 200 - 成功
    savePlaying: async function (
      worldId,
      scenarioId,
      characterId,
      chooseId,
      consumeItem,
      playing,
      onlyPlayingFlg,
      phase,
      tagId,
      onlyTeamTagFlg
    ) {
      const scenarioPlayingRequest = {
        worldId: worldId,
        scenarioId: scenarioId,
        characterId: characterId,
        chooseId: chooseId,
        consumeItem: consumeItem,
        playing: playing,
        onlyPlayingFlg: onlyPlayingFlg,
        phase: phase,
        tagId: tagId,
        onlyTeamTagFlg: onlyTeamTagFlg
      }
      let url = this.serverPath + `/api/v2/ScenarioPlaying/save`
      return await axios.post(url, scenarioPlayingRequest)
    },
    getOrderText(orderStatus) {

      if (orderStatus === 'Preorder') {
        return '受注前'
      }
      if (orderStatus === 'Ordering') {
        return '受注中'
      }
      if (orderStatus === 'Inviting') {
        return '招待中'
      }
      if (orderStatus === 'Released') {
        return 'リリース済'
      }
      if (orderStatus === 'Cancel') {
        return 'キャンセル'
      }
      if (orderStatus === 'CheckingByDirector') {
        return '運営確認中'
      }
      if (orderStatus === 'CheckingByMaster') {
        return 'マスター確認中'
      }
      if (orderStatus === 'OpenRequesting') {
        return 'オープンリクエスト'
      }
      if (orderStatus === 'Ordered') {
        return '発注確定'
      }
      if (orderStatus === 'Returned') {
        return '差し戻し'
      }
      if (orderStatus === 'Submitting') {
        return '提出中'
      }
      if (orderStatus === 'ReturnedByDirector') {
        return '運営差し戻し'
      }
      if (orderStatus === 'ReturnedByMaster') {
        return 'マスター差し戻し'
      }
      return ''
    },

    // POST /api/v2/ScenarioPlaying/delete/{worldId}/{scenarioId}/{characterId}
    //
    // 説明：
    // プレイング情報を削除します。
    // パスパラメータ：
    // {worldId} - ワールドID
    // {scenarioId} - シナリオID
    // {characterId} - キャラクターID
    // レスポンス：
    // なし
    // ステータスコード：
    // 201 - 成功
    deletePlaying: async function (worldId, scenarioId, characterId) {
      let url =
        this.serverPath +
        `/api/v2/ScenarioPlaying/delete/${worldId}/${scenarioId}/${characterId}`
      return await axios.delete(url)
    },

    async submitRequestScenario(orderId, scenarioTitle, difficultyNo, openDateTime, rewardNo, recruitmentPeriod, writingPeriod, privateReplayFlg, openingComment, openingContent, scenarioDescription, masterMsg, characterList) {

      const scenarioInfo = {
        scenarioTitle: scenarioTitle,
        difficultyNo: difficultyNo,
        openDateTime: openDateTime,
        rewardNo: rewardNo,
        recruitmentPeriod: recruitmentPeriod,
        privateReplayFlg: privateReplayFlg,
        openingComment: openingComment,
        openingContent: openingContent,
        scenarioDescription: scenarioDescription,
        masterMsg: masterMsg
      }

      const body = {
        orderId: orderId,
        scenarioInfo: scenarioInfo,
        writingPeriod: writingPeriod,
        characterList: characterList
      }

      let url = this.serverPath + `/api/ScenarioOrder/submitRequestScenario`

      return await axios.post(url, body)
    },

    async updateRequestScenarioByMaster(orderId, scenarioTitle, difficultyNo, openDateTime, rewardNo, recruitmentPeriod, writingPeriod, privateReplayFlg, openingComment, openingContent, scenarioDescription, masterMsg, scenarioId, characterList) {

      const scenarioInfo = {
        scenarioTitle: scenarioTitle,
        difficultyNo: difficultyNo,
        openDateTime: openDateTime,
        rewardNo: rewardNo,
        recruitmentPeriod: recruitmentPeriod,
        privateReplayFlg: privateReplayFlg,
        openingComment: openingComment,
        openingContent: openingContent,
        scenarioDescription: scenarioDescription,
        masterMsg: masterMsg,
        scenarioId: scenarioId
      }

      const body = {
        orderId: orderId,
        scenarioInfo: scenarioInfo,
        writingPeriod: writingPeriod,
        characterList: characterList
      }

      let url = this.serverPath + `/api/ScenarioOrder/submitRequestScenario`

      return await axios.put(url, body)
    },

    async getApprovalListByWorldId(worldId) {
      const url = this.serverPath + `/api/ScenarioInfo/admin/getScenarioInfoForApproval/${worldId}`
      const res = await axios.get(url)
      if (res.data) {
        return res.data
      }
    },
    //getScenarioInfo/{worldId}/{scenarioId}
    async getScenarioInfoForAdmin(worldId, scenarioId) {
      const url = this.serverPath + `/api/ScenarioInfo/admin/getScenarioInfo/${worldId}/${scenarioId}`
      const res = await axios.get(url)
      if (res.data) {
        return res.data
      }
    },
    async updateScenarioInfoForAdmin(scenarioInfo) {
      const body = {
        scenarioInfo: scenarioInfo
      }
      let url = this.serverPath + `/api/ScenarioInfo/admin`
      return await axios.put(url, body)
    },
    ///check
    //却下時 2 承認時 3
    async checkScenarioInfoForAdmin(worldId, scenarioId, status) {
      const body = {
        worldId: worldId,
        scenarioId: scenarioId,
        status: status
      }
      let url = this.serverPath + `/api/ScenarioInfo/admin/check`
      return await axios.post(url, body)
    },

    async checkScenarioAppendInfoForAdmin(worldId, scenarioId, phase, status) {
      const body = {
        worldId: worldId,
        scenarioId: Number(scenarioId),
        phase: Number(phase),
        status: status
      }
      let url = this.serverPath + `/api/AppendScenario/admin/check`
      return await axios.post(url, body)
    },


    //masterList取得
    ///api/masterRoom/v2/list/{worldId}
    // HTTP Method: GET
    async getMasterRoomListV2(worldId) {
      let url = this.serverPath + `/api/masterRoom/v2/list/${worldId}`
      const res = await axios.get(url)
      return res.data
    },

    //teamTagController関係
    async getScenarioPlayingTeamTag(worldId, scenarioId, characterId) {
      return axios.get(this.serverPath + `/api/ScenarioPlayingTeamTag/getList/${worldId}/${scenarioId}/${characterId}`)
    },
    async createTeamTag(worldId, scenarioId, characterId, tagName) {
      const request = {
        worldId: worldId,
        scenarioId: scenarioId,
        characterId: characterId,
        tagName: tagName
      }
      axios.post(this.serverPath + '/api/ScenarioPlayingTeamTag', request)
        .then(() => {
        })
        .catch(err => {
          console.log(err)
        })
    },
    //request.getWorldId(), request.getScenarioId(), request.getTagId(), request.getTagName(), userInfo.getUid(), request.getCharacterId()
    async updateTeamTag(worldId, scenarioId, tagId, tagName, characterId) {
      const request = {
        worldId: worldId,
        scenarioId: scenarioId,
        tagId: tagId,
        tagName: tagName,
        characterId: characterId
      }
      axios.put(this.serverPath + '/api/ScenarioPlayingTeamTag', request)
        .then(() => {
          // 正常終了時の処理
        })
        .catch(err => {
          // エラーハンドリング
          console.log(err)
        })
    },
    async deleteTeamTag(worldId, scenarioId, tagId) {
      axios.delete(this.serverPath + `/api/ScenarioPlayingTeamTag/${worldId}/${scenarioId}/${tagId}`)
        .then(() => {
          // 正常終了時の処理
        })
        .catch(err => {
          // エラーハンドリング
          console.log(err)
        })
    },
    async getScenarioPlayingTeamTagList(worldId, scenarioId, phase, tagId) {
      return axios.get(this.serverPath + `/api/ScenarioPlayingTeamTag/getCharacterList/${worldId}/${scenarioId}/${phase}/${tagId}`)
    },

    ////api/ScenarioInfo/master/getScenarioInfoForRelease/{worldId}
    // HTTP Method: GET
    async getScenarioInfoForRelease(worldId) {
      let url = this.serverPath + `/api/ScenarioInfo/master/getScenarioInfoForRelease/${worldId}`
      const res = await axios.get(url)
      if (res.data) {
        return res.data
      }
    },

    ///api/ScenarioInfo/master
    // HTTP Method: Post
    async createScenarioInfoForMaster(scenarioInfo, scenarioChoiceList, characterList, writingPeriod) {
      const body = {
        scenarioInfo: scenarioInfo,
        scenarioChoiceList: scenarioChoiceList,
        relatedCharacterList: characterList,
        writingPeriod: writingPeriod
      }
      let url = this.serverPath + `/api/ScenarioInfo/master`
      return await axios.post(url, body)
    },
    ///api/ScenarioInfo/master
    // HTTP Method: Post
    async updateScenarioOpeningInfosForAdmin(scenarioInfo, scenarioChoiceList, characterList, writingPeriod) {
      const body = {
        scenarioInfo: scenarioInfo,
        scenarioChoiceList: scenarioChoiceList,
        relatedCharacterList: characterList,
        writingPeriod: writingPeriod
      }
      let url = this.serverPath + `/api/ScenarioInfo/master`
      return await axios.put(url, body)
    },
    //teamTagを取得
    ////getTagTitle/{worldId}/{scenarioId}/{tagId}
    // HTTP Method: GET
    async getScenarioTeamTagTitle(worldId, scenarioId, tagId) {
      if (!tagId) return null;
      let url = this.serverPath + `/api/ScenarioPlayingTeamTag/getTagTitle/${worldId}/${scenarioId}/${tagId}`
      const res = await axios.get(url)
      return res.data
    },

    //getScenarioInfoForAppend
    async getScenarioInfoForAppend(worldId, scenarioId, phase) {
      let url = this.serverPath + `/api/ScenarioInfo/master/getScenarioInfoForAppend/${worldId}/${scenarioId}/${phase}`
      const res = await axios.get(url)
      return res.data
    },

    //appendScenarioInfo
    ///api/AppendScenario/master/appendScenario
    async appendScenarioByMaster(worldId, scenarioId, phase, scenarioAppendInfo, scenarioChoicesList) {
      const body = {
        worldId: worldId,
        scenarioId: Number(scenarioId),
        phase: Number(phase),
        scenarioAppendInfo: scenarioAppendInfo,
        scenarioChoicesList: scenarioChoicesList
      }
      let url = this.serverPath + `/api/AppendScenario/master/appendScenario`
      return await axios.post(url, body)
    },

    //put
    //updateScenarioAppendOpeningInfosForAdmin
    async updateScenarioAppendOpeningInfosForAdmin(scenarioAppendInfo, scenarioChoicesList) {
      const body = {
        scenarioAppendInfo: scenarioAppendInfo,
        scenarioChoicesList: scenarioChoicesList
      }
      let url = this.serverPath + `/api/AppendScenario/master/update`
      return await axios.put(url, body)
    },

    //check

  }
}
